@font-face {
  font-family: 'smartstripe';
  src:  url('../fonts/smartstripe/smartstripe.eot?hc78vj');
  src:  url('../fonts/smartstripe/smartstripe.eot?hc78vj#iefix') format('embedded-opentype'),
    url('../fonts/smartstripe/smartstripe.ttf?hc78vj') format('truetype'),
    url('../fonts/smartstripe/smartstripe.woff?hc78vj') format('woff'),
    url('../fonts/smartstripe/smartstripe.svg?hc78vj#smartstripe') format('svg');
  font-weight: normal;
  font-style: normal;
}

.ss-icons {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'smartstripe' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ss-icon-card-process:before {
  content: "\e900";
}
.ss-icon-design:before {
  content: "\e901";
}
.ss-icon-finishing:before {
  content: "\e902";
}
.ss-icon-personalised:before {
  content: "\e903";
}
.ss-icon-printing:before {
  content: "\e904";
}
.ss-icon-qaqc-packaging:before {
  content: "\e905";
}
.ss-icon-sales:before {
  content: "\e906";
}
.ss-icon-single-card:before {
  content: "\e907";
}
.ss-icon-stock:before {
  content: "\e908";
}
.ss-icon-account:before {
  content: "\e909";
}
.ss-icon-logo:before {
  content: "\e90a";
}
.ss-icon-routing-laser:before {
  content: "\e90b";
}
.ss-icon-stage:before {
  content: "\e90c";
}
.ss-icon-texture:before {
  content: "\e90d";
}
.ss-icon-rfid:before {
  content: "\e90e";
}
.ss-icon-thickness:before {
  content: "\e90f";
}
